import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import grayArrow from '../../assets/gray-arrow-icon.png';
import CookiesModalContent from '../../components/CookiesModalContent';
import FooterComplete from '../../components/FooterComplete';
import Header from '../../components/Header';
import MenuButton from '../../components/Header/MenuButton';
import Loader from '../../components/Loader';
import Showcase from '../../components/Showcase';
import Toolbar from '../../components/Toolbar';
import UserPointsMobile from '../../components/UserPointsMobile';
import { useAuth } from '../../contexts/AuthContext';
import { useBottomModal } from '../../contexts/BottomModalContext';
import { useSpecies } from '../../contexts/SpeciesContext';
import api from '../../services/api';
import HomeBlogSwipper from './HomeBlogSwipper';
import HomeStepper from './HomeStepper';
import CampaignInfosHubItem from './HubItems/CampaignInfosHubItem';
import GillHubItem from './HubItems/GillHubItem';
import UserCategoryHubItem from './HubItems/UserCategoryHubItem';
import UserCategoryHubItemExtended from './HubItems/UserCategoryHubItemExtended';
import * as S from './style';

const faqs = [
    {
        question: 'O que é a programa Open Farm® Cargill?',
        answer: <>
            O Open Farm® é o programa de relacionamento da Cargill que vem com um dos nossos principais objetivos como empresa, colocar o cliente no centro. O Open Farm, visa atender a real necessidade do cliente e estar presente em todos os momentos. Queremos que através do Programa o cliente reinvista em seu negócio. Acreditamos na relação de parceria e transparência entre as empresas e o Open Farm veio para atender com excelência os nossos parceiros.
        </>
    },
    {
        question: 'Como efetivar a participação no Programa?',
        answer: <>
            Para que a participação seja considerada válida, o participante deverá informar seus dados cadastrais incluindo endereço eletrônico (e-mail) e celular no site www.openfarmcargill.com.br em área específica para cadastro
        </>
    },
    {
        question: 'Como atualizar meus dados?',
        answer: <>
            Para atualizar seus dados, entre em “Menu” e depois “Meus Dados”. Na tela, você pode atualizar os seus dados pessoais e dados de suas empresas.
        </>
    }
]

function Home () {
    const { showBottomModal } = useBottomModal()
    const { userCan } = useAuth()
    const { specieSelected } = useSpecies()
    const navigate = useNavigate()
    const [faqShown, setFaqShown] = useState<number | null>(null)
    const [benefitLink, setBenefitLink] = useState<string>('')
    const [reinvestLink, setReinvestLink] = useState<string>('')
    const [reinvestLeiteLojaLink, setReinvestLeiteLojaLink] = useState<string>('')

    const toggleFAQ = useCallback((index: number) => {
        setFaqShown(faqShown === index ? null : index);
    }, [faqShown])

    const handleReinvestLink = useCallback(async () => {
        if (!specieSelected) return
        const response = await api.get(`/cargill-catalogo/${specieSelected.catalog}`)
        setReinvestLink(response.data.url)
    }, [setReinvestLink, specieSelected])


    const handleBenefitsLink = useCallback(async () => {
        const response = await api.get(`/cargill-catalogo/81544`)
        setBenefitLink(response.data.url)
    }, [])

    const handleReinvestLeiteLojaLink = useCallback(async () => {
        const response = await api.get(`/cargill-catalogo/81554`)
        setReinvestLeiteLojaLink(response.data.url)
    }, [])

    useEffect(() => {
        const modal = localStorage.getItem('modal1')
        if (!modal) {
            showBottomModal(<CookiesModalContent />)
        }
    }, [showBottomModal])

    useEffect(() => {
        handleReinvestLink()
        handleBenefitsLink()
        handleReinvestLeiteLojaLink()
    }, [handleBenefitsLink, handleReinvestLeiteLojaLink, handleReinvestLink])

    if (!specieSelected) return <Loader />

    return <S.Home>
        <Header element={<MenuButton />} borderBottom={true} showDesktopWidgets/>
        <UserPointsMobile />
        <HomeStepper />
        <S.HomeHub>
            <S.HomeHubTopCardsContainer>
                {[2, 4, 5].includes(specieSelected?.id) && <>
                    <div className='first-card' onClick={() => navigate('/como-funciona')}>
                        <UserCategoryHubItemExtended specieSelectedInfos={specieSelected} subtitle={specieSelected?.id === 4 ? 'Fábrica' : ''} />
                    </div>

                    <div className="group">
                        {specieSelected.childrens.map(children => <div className='second-card' onClick={() => navigate('/como-funciona')}>
                            <UserCategoryHubItem
                                specieSelectedInfos={children}
                                subtitle={children?.catalog === 81554 ? 'Loja' : ''}
                            />
                        </div>)}


                        {userCan('read:gill') && <div className='third-card'>
                            <GillHubItem />
                        </div>}
                    </div>
                </>}

                {![2, 4, 5].includes(specieSelected?.id) && <>
                    <div className="group">
                        <div className='second-card' onClick={() => navigate('/como-funciona')}>
                            <UserCategoryHubItem
                                specieSelectedInfos={specieSelected}
                                subtitle={specieSelected?.catalog === 81543 ? 'Loja' : ''}
                            />
                        </div>
                        {userCan('read:gill') && <div className='third-card'>
                            <GillHubItem />
                        </div>}
                    </div>
                </>}
                <div className='fourth-card' onClick={() => navigate('/como-funciona')}>
                    <CampaignInfosHubItem />
                </div>
            </S.HomeHubTopCardsContainer>
            {userCan('read:reinvest-catalog') && <>
                <Showcase catalog={specieSelected.catalog} catalogType='reinvest' catalogName={specieSelected?.catalog === 81543 ? 'Reinvestimento Fábrica' : 'Reinvestimento'} link={reinvestLink} />

                {specieSelected && specieSelected.childrens.map(children =>
                    <Showcase {...children} catalogType='reinvest' catalogName='Reinvestimento Loja' link={reinvestLeiteLojaLink} />
                )}
            </>}
            {userCan('read:benefits-catalog') && <Showcase catalog={81544} catalogType='benefit' catalogName='Benefício' link={benefitLink} />}
            <S.HomeSwipperHeader>
                <S.HomeSwipperHeaderTitle>Confira as últimas no blog</S.HomeSwipperHeaderTitle>
                <S.HomeSwipperHeaderActionTitle onClick={() => navigate('/blog')}>Ver mais</S.HomeSwipperHeaderActionTitle>
            </S.HomeSwipperHeader>
            <HomeBlogSwipper />
            <S.FAQContainer>
                <h2>Perguntas frequentes</h2>
                <h3>Tem alguma dúvida? Confira as perguntas frequentes.</h3>
                {faqs.map((faq, index) => (
                    <S.FAQItem key={index}>
                        <S.FAQItemQuestion onClick={() => toggleFAQ(index)} $isToggled={faqShown === index}>
                            {faq.question}
                            <img src={grayArrow} alt="Ícone de seta, indicando um link que pode ser clicado" />
                        </S.FAQItemQuestion>
                        <S.FAQItemAnswer $isShown={faqShown === index}>
                            {faq.answer}
                        </S.FAQItemAnswer>
                    </S.FAQItem>
                ))}
                <S.FAQButton onClick={() => navigate('/perguntas-frequentes')}>Ver todas as perguntas</S.FAQButton>
            </S.FAQContainer>
        </S.HomeHub>
        <FooterComplete />
        <Toolbar />
    </S.Home>
}

export default Home
import styled from 'styled-components';

export const HomeStepperContainer = styled.div`
    width: 100%;
    position: relative;
    margin-top: 24px;

    @media only screen and (min-width: 960px) {
        margin-top: 0px;
    }
`

export const HomeStepper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: hidden;
    
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const HomeStepperSlide = styled.div`
    width: 100%;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    padding: 0px 36px;
    user-select: none;
    max-height: 400px;

    @media only screen and (min-width: 960px) {
        padding: 0;
    }
`;

export const HomeStepperSlideImage = styled.img`
    width: 318px;
    margin: 0;
    padding: 0;
    cursor: pointer;
    border-radius: 30px;
    max-height: 200px;

    @media only screen and (min-width: 960px) {
        width: 100%;
        max-height: none;
        border-radius: 0px;
        padding: 0;
    }
`;

export const HomeStepperNavigation = styled.div`
    margin-top: 8px;
    width: 100%;
    border-radius: 50%;
    padding: 0px 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    
    @media only screen and (min-width: 960px) {
        position: absolute;
        bottom: 54px;
    }
    > div {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
`;

export const HomeStepperNavigationDot = styled.div<{ $active: boolean }>`
    cursor: pointer;
    height: 12px;
    width: ${({ $active }) => ($active ? '34px' : '12px')};
    background-color: ${({ $active }) => ($active ? 'var(--green-neon)' : '#EBF3EF')};
    border-radius: ${({ $active }) => ($active ? '8px' : '50%')};
    transition: all 0.3s ease-in-out;
`;
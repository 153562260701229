export enum State {
  READY,
  SENDING,
  SUCCESS
}
export interface SlideContentProps {
  path: string
  link: string
  order: number
}

export interface SlidesProps {
  status: State
  banners: SlideContentProps[]
}
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../../services/api";
import { SlideContentProps, SlidesProps, State } from "./HomeStepper.props";
import * as S from './style';

function HomeStepper () {
    const [activeSlide, setActiveSlide] = useState<number>(0)
    const [bannersList, setBannersList] = useState<SlidesProps>({
        status: State.READY,
        banners: []
    })

    const handleRedirect = useCallback(async (value: SlideContentProps) => {
    let link = value.link
    if (!link.startsWith('http://') && !link.startsWith('https://')) {
      link = 'https://' + link
    }
    window.location.href = link
    }, [])

    const handleDotClick = useCallback((index: number) => {
        setActiveSlide(index)
        const slideElement = document.getElementById(`slide-${index}`)
        if (slideElement) {
            slideElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
            slideElement.scrollLeft -= 36;
        }
    }, [])

    const getBanners = useCallback(async () => {
        try {
            setBannersList((prevState) => ({
                ...prevState,
                status: State.SENDING
            }))
            const response = await api.get('/banner');
            const sortedBanners = response.data.banners.sort((a: SlideContentProps, b: SlideContentProps) => a.order - b.order)
            setBannersList({
                status: State.SUCCESS,
                banners: sortedBanners,
            })
        } catch (error) {
            toast.error('Não foi possível carregar os banners.', { theme: 'colored' })
        }
    }, [])

    useEffect(() => {
        const slideElement = document.getElementById("scroll")
        const touchStart = (evt: any) => {
            if (!evt.touches[0]) return
            startX = evt.touches[0].clientX
        }
        const touchEnd = (evt: any) => {
            if (!evt.changedTouches[0]) return
            if (startX - evt.changedTouches[0].clientX < 0) {
                if (activeSlide === 0) return
                handleDotClick(activeSlide - 1)
            }
            if (startX - evt.changedTouches[0].clientX > 0) {
                if (activeSlide === bannersList.banners.length - 1) return
                handleDotClick(activeSlide + 1)
            }
        }
        const mouseStart = (evt: any) => {
            evt.preventDefault()
            startX = evt.screenX
        }
        const mouseEnd = (evt: any) => {
            if (startX - evt.screenX < -5) {
                evt.preventDefault()
                if (activeSlide === 0) return
                handleDotClick(activeSlide - 1)
            }
            if (startX - evt.screenX > 5) {
                evt.preventDefault()
                if (activeSlide === bannersList.banners.length - 1) return
                handleDotClick(activeSlide + 1)
            }
        }

        let startX = 0
        slideElement?.addEventListener("touchstart", touchStart)
        slideElement?.addEventListener("touchend", touchEnd)
        slideElement?.addEventListener("mousedown", mouseStart)
        slideElement?.addEventListener("mouseup", mouseEnd)
        return () => {
            slideElement?.removeEventListener('touchstart', touchStart)
            slideElement?.removeEventListener('touchend', touchEnd)
            slideElement?.removeEventListener('mousedown', mouseStart)
            slideElement?.removeEventListener('mouseup', mouseEnd)
        }
    }, [activeSlide, bannersList.banners.length, handleDotClick])

    useEffect(() => {
        getBanners()
    }, [getBanners])

    if (bannersList.status !== State.SUCCESS) return <></>

    return <S.HomeStepperContainer>
        <S.HomeStepper>
            <S.HomeStepperSlide id="scroll">
                {bannersList.banners.map((slide, index) => (
                    <S.HomeStepperSlideImage
                        key={index}
                        id={`slide-${index}`}
                        src={slide.path}
                        onClick={() => handleRedirect(slide)}
                    />
                ))}
            </S.HomeStepperSlide>
        </S.HomeStepper>
        <S.HomeStepperNavigation>
            <div>
                {bannersList.banners.map((_, index) => (
                    <S.HomeStepperNavigationDot
                        key={index}
                        $active={index === activeSlide}
                        onClick={() => handleDotClick(index)}
                        draggable="false"
                    />
                ))}
            </div>
        </S.HomeStepperNavigation>
    </S.HomeStepperContainer>
}

export default HomeStepper